.section-skill {
  width: 100%;
  height: 100%;
  @include respond(tab-port) {
    height: 100%;
  }
  @include respond(phone) {
    height: 100%;
  }

  .skill-content {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: 0.05rem;
    padding-top: 7rem;
    text-align: left;

    @include respond(tab-port) {
      margin-right: 5rem;
      padding-top: 4rem;
    }
    .skill-title {
      text-transform: uppercase;
      color: $color-primary;
      font-weight: 600;
      font-size: 3.7rem;
      text-align: center;
    }
    .skill-container {
      width: 100%;

      .skill-box {
        width: 100%;
        margin: 1.5rem 0;

        .title {
          display: block;
          font-size: 1.4rem;
          color: $color-primary;
        }
        .skill-bar {
          height: 0.8rem;
          width: 100%;
          border-radius: 0.6rem;
          margin-top: 0.6rem;
          background: #494f56;
        }
        .skill-per {
          position: relative;
          display: block;
          height: 100%;
          width: 95%;
          border-radius: 0.6rem;
          background-color: $color-primary;
          opacity: 0;
          animation: progress 0.4s ease-in-out forwards;

          .tooltip {
            position: absolute;
            right: -1.4rem;
            top: -2.8rem;
            font-size: 0.9rem;
            color: white;
            padding: 0.2rem 0.6rem;
            border-radius: 0.3rem;
            background-color: $color-primary;
            z-index: 1;

            &::before {
              content: "";
              position: absolute;
              left: 20%;
              bottom: -2px;
              height: 1rem;
              width: 1rem;
              z-index: -1;
              background-color: $color-primary;
              // transform: translateX(-50%) rotate(45deg);
            }
          }
        }
        .css {
          width: 80%;
          animation-delay: 0.2s;
        }
        .js {
          width: 70%;
          animation-delay: 0.3s;
        }
        .react {
          width: 85%;
          animation-delay: 0.35s;
        }
        .node {
          width: 80%;
          animation-delay: 0.4s;
        }
        .mongo {
          animation-delay: 0.6s;
          width: 65%;
        }
        .git {
          animation-delay: 0.7s;
          width: 80%;
        }
      }
    }
  }

  .image-side {
    @include respond(phone) {
      display: none;
    }

    .img-bg {
      background-color: $color-primary;
      width: 35rem;
      height: 35rem;
      top: 20%;
      right: 10%;

      @include respond(tab-land) {
        width: 30rem;
        height: 30rem;
      }
      @include respond(tab-port) {
        width: 25rem;
        height: 25rem;
        top: 15%;
        left: 10%;
      }
      @include respond(phone) {
        top: 15%;
      }

      .skill-image {
        left: -10%;
        top: -10%;
        img {
          width: 35rem;
          height: 35rem;
          object-fit: cover;

          @include respond(tab-land) {
            width: 30rem;
            height: 30rem;
          }
          @include respond(tab-port) {
            width: 25rem;
            height: 25rem;
          }
        }
      }
    }
  }
}
@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
