.split-background {
  width: 100%;
  height: 95vh;
  position: relative;

  @include respond(tab-port) {
    height: 70vh;
  }

  @include respond(phone) {
    height: 65vh;
  }

  hr {
    opacity: 1;
    border: 0.5px solid $color-primary;
  }

  .left-bg {
    position: absolute;
    width: 65%;
    height: 100%;
    // background-color: $color-secondary;
    left: 0;
    margin-top: -8rem;
  }
  .right-bg {
    position: absolute;
    width: 35%;
    height: 100%;
    background-color: $color-primary;
    right: 0;
    margin-top: -8rem;
  }
}
.hero-content {
  position: relative;
  height: 100%;
  .hello {
    position: absolute;
    left: 15%;
    top: 20%;
    color: $color-primary;
    font-size: 2rem;
    font-weight: 400;
  }
  .name {
    position: absolute;
    left: 15%;
    top: 25%;
    text-transform: uppercase;
    width: 35rem;
    font-size: 4.5rem;
    color: white;
    font-weight: 600;
    letter-spacing: 0.05rem;
    text-align: left;
  }
}
.job {
  position: absolute;
  left: 15%;
  top: 43%;
  font-size: 1.8rem;
  color: white;
  // border: 2px solid $color-primary;

  .job-list {
    align-items: center;
    border: 1px solid $color-primary;
  }
}
.follow {
  position: absolute;
  left: 15%;
  top: 60%;
  color: white;
  &__heading {
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  &__list {
    gap: 1rem;
    li {
      a {
        color: $color-primary;
        text-decoration: none;

        &:hover {
          color: white;
          transition: all 0.3s;
        }
      }
    }
  }
}
.items {
  padding: 2rem;
  a {
    color: $color-secondary;
    // .hire-icon{
    //   font-size: 2.5rem;
    //   // width: 3rem;
    // }
  }
}
.icon {
  background-color: $color-primary;
  border: 1px solid $color-primary;
  color: $color-primary;
  &:hover {
    background-color: $color-secondary;
    color: $color-primary;
    transition: all 0.7s;
    a {
      color: $color-primary;
    }
  }
}
.hero-image {
  right: 17%;
  bottom: 12.5%;
  height: 56rem;

  @include respond(tab-land) {
    position: absolute;
    right: 15%;
  }

  @include respond(tab-port) {
    position: absolute;
    right: 5%;
    height: 45rem;
    bottom: 20%;
  }
  @include respond(phone) {
    height: 38rem;
    bottom: 35%;
    border-left: 2px solid $color-primary;
    border-right: 2px solid $color-secondary;
    border-radius: 50%;
  }
}
