.navigation {
  #nav-check {
    display: none;
  }

  .nav-btn {
    color: #fff;
    position: fixed;
    height: 4rem;
    width: 4rem;
    top: 2.3rem;
    right: 5rem;
    z-index: 1500;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;

    box-shadow: 0 1rem 3rem rgba($color-primary, 0.05);
  }

  .nav-bg {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: fixed;
    top: 3.5rem;
    right: 4.5rem;
    background-image: radial-gradient($color-primary, $color-secondary);
    z-index: 99;

    transition: transform 0.5s;
  }

  #nav-check:checked + .check-elements > .nav-bg {
    transform: scale(80);
  }

  .nav-bar {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    width: 0;

    transition: all 0.5s;

    .nav-items {
      margin: 1rem;
    }

    .nav-links {
      &:link,
      &:visited {
        display: inline-block;
        font-size: 2.5rem;
        font-weight: 400;
        color: white;
        text-transform: uppercase;
        background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 50%,
          white 50%
        );
        background-size: 250%;
        padding: 0.2rem 2rem;
        transition: all 0.5s;
      }

      &:hover,
      &:active {
        background-position: 100%;
        color: $color-primary;
        transform: translateX(0.7rem);
      }

      span {
        margin-right: 1.5rem;
      }
    }
  }

  #nav-check:checked + .check-elements > .nav-bar {
    opacity: 1;
    width: 100%;
  }

  //Icon
  .nav-icon {
    position: relative;
    margin-top: 3.7rem;

    &,
    &::before,
    &::after {
      width: 2.5rem;
      height: 2px;
      background-color: $color-secondary;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.5s;
    }

    &::before {
      top: -0.7rem;
    }
    &::after {
      bottom: -0.7rem;

      @include respond(phone) {
        bottom: -0.6rem;
      }
    }
  }
  #nav-check:checked ~ .nav-btn > .nav-icon {
    background-color: transparent;
  }
  #nav-check:checked ~ .nav-btn > .nav-icon::before {
    transform: rotate(-135deg);
    top: 0;
    transition: all 0.5s;
  }
  #nav-check:checked ~ .nav-btn > .nav-icon::after {
    transform: rotate(135deg);
    top: 0;
    transition: all 0.5s;
  }
}
