@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@500&family=Playfair+Display:wght@600&family=Roboto+Condensed:wght@500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 31.25%;
  }
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: #1f242b;
}

.absolute {
  position: absolute;
}

.row {
  width: 115rem;
  padding: 0 7rem;
}

.relative {
  position: relative;
}

.white {
  color: white;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.z-50 {
  z-index: 500;
}

.flex {
  display: flex;
}

.margin-bottom-small {
  margin-bottom: 1.8rem;
}

.margin-bottom-medium {
  margin-bottom: 4rem;
}

.margin-bottom-large {
  margin-bottom: 8rem;
}

.margin-top-large {
  margin-top: 8rem;
}

.margin-horizontal {
  margin: 1rem auto;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-top-medium {
  margin-top: 4rem;
}

.col-50 {
  float: left;
  width: 50%;
}

.text-center {
  text-align: center;
}

.center-head {
  align-items: center;
}

ul {
  padding: 0;
}

.navigation #nav-check {
  display: none;
}
.navigation .nav-btn {
  color: #fff;
  position: fixed;
  height: 4rem;
  width: 4rem;
  top: 2.3rem;
  right: 5rem;
  z-index: 1500;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 1rem 3rem rgba(42, 216, 130, 0.05);
}
.navigation .nav-bg {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  top: 3.5rem;
  right: 4.5rem;
  background-image: radial-gradient(#2ad882, #1f242b);
  z-index: 99;
  transition: transform 0.5s;
}
.navigation #nav-check:checked + .check-elements > .nav-bg {
  transform: scale(80);
}
.navigation .nav-bar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  width: 0;
  transition: all 0.5s;
}
.navigation .nav-bar .nav-items {
  margin: 1rem;
}
.navigation .nav-bar .nav-links:link, .navigation .nav-bar .nav-links:visited {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
  background-size: 250%;
  padding: 0.2rem 2rem;
  transition: all 0.5s;
}
.navigation .nav-bar .nav-links:hover, .navigation .nav-bar .nav-links:active {
  background-position: 100%;
  color: #2ad882;
  transform: translateX(0.7rem);
}
.navigation .nav-bar .nav-links span {
  margin-right: 1.5rem;
}
.navigation #nav-check:checked + .check-elements > .nav-bar {
  opacity: 1;
  width: 100%;
}
.navigation .nav-icon {
  position: relative;
  margin-top: 3.7rem;
}
.navigation .nav-icon, .navigation .nav-icon::before, .navigation .nav-icon::after {
  width: 2.5rem;
  height: 2px;
  background-color: #1f242b;
  display: inline-block;
}
.navigation .nav-icon::before, .navigation .nav-icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.5s;
}
.navigation .nav-icon::before {
  top: -0.7rem;
}
.navigation .nav-icon::after {
  bottom: -0.7rem;
}
@media (max-width: 37.5em) {
  .navigation .nav-icon::after {
    bottom: -0.6rem;
  }
}
.navigation #nav-check:checked ~ .nav-btn > .nav-icon {
  background-color: transparent;
}
.navigation #nav-check:checked ~ .nav-btn > .nav-icon::before {
  transform: rotate(-135deg);
  top: 0;
  transition: all 0.5s;
}
.navigation #nav-check:checked ~ .nav-btn > .nav-icon::after {
  transform: rotate(135deg);
  top: 0;
  transition: all 0.5s;
}

.split-background {
  width: 100%;
  height: 95vh;
  position: relative;
}
@media (max-width: 56.25em) {
  .split-background {
    height: 70vh;
  }
}
@media (max-width: 37.5em) {
  .split-background {
    height: 65vh;
  }
}
.split-background hr {
  opacity: 1;
  border: 0.5px solid #2ad882;
}
.split-background .left-bg {
  position: absolute;
  width: 65%;
  height: 100%;
  left: 0;
  margin-top: -8rem;
}
.split-background .right-bg {
  position: absolute;
  width: 35%;
  height: 100%;
  background-color: #2ad882;
  right: 0;
  margin-top: -8rem;
}

.hero-content {
  position: relative;
  height: 100%;
}
.hero-content .hello {
  position: absolute;
  left: 15%;
  top: 20%;
  color: #2ad882;
  font-size: 2rem;
  font-weight: 400;
}
.hero-content .name {
  position: absolute;
  left: 15%;
  top: 25%;
  text-transform: uppercase;
  width: 35rem;
  font-size: 4.5rem;
  color: white;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-align: left;
}

.job {
  position: absolute;
  left: 15%;
  top: 43%;
  font-size: 1.8rem;
  color: white;
}
.job .job-list {
  align-items: center;
  border: 1px solid #2ad882;
}

.follow {
  position: absolute;
  left: 15%;
  top: 60%;
  color: white;
}
.follow__heading {
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
}
.follow__list {
  gap: 1rem;
}
.follow__list li a {
  color: #2ad882;
  text-decoration: none;
}
.follow__list li a:hover {
  color: white;
  transition: all 0.3s;
}

.items {
  padding: 2rem;
}
.items a {
  color: #1f242b;
}

.icon {
  background-color: #2ad882;
  border: 1px solid #2ad882;
  color: #2ad882;
}
.icon:hover {
  background-color: #1f242b;
  color: #2ad882;
  transition: all 0.7s;
}
.icon:hover a {
  color: #2ad882;
}

.hero-image {
  right: 17%;
  bottom: 12.5%;
  height: 56rem;
}
@media (max-width: 75em) {
  .hero-image {
    position: absolute;
    right: 15%;
  }
}
@media (max-width: 56.25em) {
  .hero-image {
    position: absolute;
    right: 5%;
    height: 45rem;
    bottom: 20%;
  }
}
@media (max-width: 37.5em) {
  .hero-image {
    height: 38rem;
    bottom: 35%;
    border-left: 2px solid #2ad882;
    border-right: 2px solid #1f242b;
    border-radius: 50%;
  }
}

.section-about {
  width: 100%;
  height: 80vh;
  margin-top: 1rem;
}
@media (max-width: 75em) {
  .section-about {
    height: 100%;
  }
}
@media (max-width: 56.25em) {
  .section-about {
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .section-about {
    height: 100%;
  }
}
.section-about .image-side {
  position: relative;
}
@media (max-width: 37.5em) {
  .section-about .image-side {
    display: none;
  }
}
.section-about .image-side .img-bg {
  background-color: #2ad882;
  width: 35rem;
  height: 35rem;
  position: absolute;
  top: 20%;
  left: 10%;
}
@media (max-width: 75em) {
  .section-about .image-side .img-bg {
    width: 30rem;
    height: 30rem;
  }
}
@media (max-width: 56.25em) {
  .section-about .image-side .img-bg {
    width: 25rem;
    height: 25rem;
    top: 15%;
    left: 10%;
  }
}
@media (max-width: 37.5em) {
  .section-about .image-side .img-bg {
    top: 15%;
  }
}
.section-about .image-side .about-image {
  position: absolute;
  right: -10%;
  top: -10%;
}
.section-about .image-side img {
  width: 35rem;
  height: 35rem;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 75em) {
  .section-about .image-side img {
    width: 30rem;
    height: 30rem;
  }
}
@media (max-width: 56.25em) {
  .section-about .image-side img {
    width: 25rem;
    height: 25rem;
  }
}
.section-about .about-content {
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.05rem;
  text-align: left;
  padding-top: 7rem;
  padding-left: 2rem;
}
@media (max-width: 56.25em) {
  .section-about .about-content {
    text-align: center;
    padding-top: 4rem;
  }
}
.section-about .about-content h1 {
  text-transform: uppercase;
  color: #2ad882;
  font-weight: 600;
  font-size: 3.7rem;
}
.section-about .about-content .about-text {
  text-align: left;
  font-weight: 400;
}
.section-about .about-content .btn-download {
  padding: 0.8rem 2.5rem;
  border: 2px solid #2ad882;
  font-size: 1.5rem;
  background: none;
  color: white;
  text-decoration: none;
  letter-spacing: 0px;
}
.section-about .about-content .btn-download:hover {
  color: #2ad882;
  border: 2px solid #2ad882;
  transition: all 0.5s;
}

.btn-primary {
  padding: 0.8rem 2.5rem;
  border: 2px solid #2ad882;
  background-color: #2ad882;
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 2rem;
  text-decoration: none;
  letter-spacing: 0px;
  color: black;
}
.btn-primary:hover {
  background-color: #1f242b;
  border: 2px solid #2ad882;
  color: white;
  transition: all 0.5s;
}

@media (max-width: 37.5em) {
  .col {
    display: block;
  }
}

.section-education {
  width: 100%;
  height: 100%;
}
@media (max-width: 56.25em) {
  .section-education {
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .section-education {
    height: 100%;
  }
}
.section-education .education-container {
  width: 50%;
  min-height: 10rem;
  margin: 5rem auto;
}
@media (max-width: 75em) {
  .section-education .education-container {
    width: 60%;
  }
}
@media (max-width: 56.25em) {
  .section-education .education-container {
    width: 70%;
  }
}
@media (max-width: 37.5em) {
  .section-education .education-container {
    width: 80%;
  }
}
.section-education .education-container .education-card {
  color: white;
  padding: 0.5rem 7rem 0 7rem;
  border: 2px solid #2ad882;
  transform: skewX(-12deg);
}
.section-education .education-container .education-card > * {
  transform: skewX(12deg);
}
.section-education .education-container .education-card .level {
  color: #2ad882;
  text-decoration: underline;
}
.section-education .education-container .education-card .institute {
  font-size: 1.5rem;
}

.hor-line {
  opacity: 1;
  border: 0.5px solid #2ad882;
}

.heading-primary {
  text-transform: uppercase;
  color: #2ad882;
  font-weight: 600;
  font-size: 3.7rem;
}

.section-skill {
  width: 100%;
  height: 100%;
}
@media (max-width: 56.25em) {
  .section-skill {
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .section-skill {
    height: 100%;
  }
}
.section-skill .skill-content {
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.05rem;
  padding-top: 7rem;
  text-align: left;
}
@media (max-width: 56.25em) {
  .section-skill .skill-content {
    margin-right: 5rem;
    padding-top: 4rem;
  }
}
.section-skill .skill-content .skill-title {
  text-transform: uppercase;
  color: #2ad882;
  font-weight: 600;
  font-size: 3.7rem;
  text-align: center;
}
.section-skill .skill-content .skill-container {
  width: 100%;
}
.section-skill .skill-content .skill-container .skill-box {
  width: 100%;
  margin: 1.5rem 0;
}
.section-skill .skill-content .skill-container .skill-box .title {
  display: block;
  font-size: 1.4rem;
  color: #2ad882;
}
.section-skill .skill-content .skill-container .skill-box .skill-bar {
  height: 0.8rem;
  width: 100%;
  border-radius: 0.6rem;
  margin-top: 0.6rem;
  background: #494f56;
}
.section-skill .skill-content .skill-container .skill-box .skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 0.6rem;
  background-color: #2ad882;
  opacity: 0;
  animation: progress 0.4s ease-in-out forwards;
}
.section-skill .skill-content .skill-container .skill-box .skill-per .tooltip {
  position: absolute;
  right: -1.4rem;
  top: -2.8rem;
  font-size: 0.9rem;
  color: white;
  padding: 0.2rem 0.6rem;
  border-radius: 0.3rem;
  background-color: #2ad882;
  z-index: 1;
}
.section-skill .skill-content .skill-container .skill-box .skill-per .tooltip::before {
  content: "";
  position: absolute;
  left: 20%;
  bottom: -2px;
  height: 1rem;
  width: 1rem;
  z-index: -1;
  background-color: #2ad882;
}
.section-skill .skill-content .skill-container .skill-box .css {
  width: 80%;
  animation-delay: 0.2s;
}
.section-skill .skill-content .skill-container .skill-box .js {
  width: 70%;
  animation-delay: 0.3s;
}
.section-skill .skill-content .skill-container .skill-box .react {
  width: 85%;
  animation-delay: 0.35s;
}
.section-skill .skill-content .skill-container .skill-box .node {
  width: 80%;
  animation-delay: 0.4s;
}
.section-skill .skill-content .skill-container .skill-box .mongo {
  animation-delay: 0.6s;
  width: 65%;
}
.section-skill .skill-content .skill-container .skill-box .git {
  animation-delay: 0.7s;
  width: 80%;
}
@media (max-width: 37.5em) {
  .section-skill .image-side {
    display: none;
  }
}
.section-skill .image-side .img-bg {
  background-color: #2ad882;
  width: 35rem;
  height: 35rem;
  top: 20%;
  right: 10%;
}
@media (max-width: 75em) {
  .section-skill .image-side .img-bg {
    width: 30rem;
    height: 30rem;
  }
}
@media (max-width: 56.25em) {
  .section-skill .image-side .img-bg {
    width: 25rem;
    height: 25rem;
    top: 15%;
    left: 10%;
  }
}
@media (max-width: 37.5em) {
  .section-skill .image-side .img-bg {
    top: 15%;
  }
}
.section-skill .image-side .img-bg .skill-image {
  left: -10%;
  top: -10%;
}
.section-skill .image-side .img-bg .skill-image img {
  width: 35rem;
  height: 35rem;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 75em) {
  .section-skill .image-side .img-bg .skill-image img {
    width: 30rem;
    height: 30rem;
  }
}
@media (max-width: 56.25em) {
  .section-skill .image-side .img-bg .skill-image img {
    width: 25rem;
    height: 25rem;
  }
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.projects-section {
  height: -moz-fit-content;
  height: fit-content;
}
.projects-section .projects .flex {
  justify-content: space-around;
}
.projects-section .projects .projects__container {
  height: 100%;
  padding: 2rem 8rem;
  gap: 2rem;
}
.projects-section .projects .projects__container .card {
  width: 30%;
  background-color: #1f242b;
  position: relative;
}
.projects-section .projects .projects__container .card__link {
  text-decoration: none;
}
.projects-section .projects .projects__container .card__link .card__image {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  width: 100%;
  height: 30rem;
}
.projects-section .projects .projects__container .card__link .card__title {
  color: white;
  text-transform: uppercase;
  margin-top: 1rem;
}
.projects-section .projects .projects__container .card__description {
  color: #2ad882;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-more {
  color: black;
  text-decoration: none;
  text-transform: capitalize;
}

.contact-table {
  border-collapse: collapse;
}
.contact-table td {
  padding: 1rem 0;
  text-align: left;
}
.contact-table td span {
  color: #2ad882;
  margin-right: 1rem;
}
.contact-table td a {
  text-decoration: none;
  color: white;
}
.contact-table th {
  writing-mode: vertical-rl;
}

.Icon {
  color: #2ad882;
}

.copyright_section .copyright {
  color: white;
}

.last-line {
  margin-bottom: 0;
}

@media (max-width: 37.5em) {
  .contact-content {
    margin-left: 3rem;
  }
}

@media (max-width: 75em) {
  .contact-container {
    height: 100%;
    margin-bottom: 10rem;
  }
}/*# sourceMappingURL=index.css.map */