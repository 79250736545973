.projects-section {
  height: fit-content;

  .projects {
    .flex {
      justify-content: space-around;
    }
    .projects__container {
      height: 100%;
      padding: 2rem 8rem;
      gap: 2rem;

      .card {
        width: 30%;
        background-color: $color-secondary;
        position: relative;
      }
      .card__link {
        text-decoration: none;

        .card__image {
          object-fit: cover;
          object-position: top;
          width: 100%;
          height: 30rem;
        }
        .card__title {
          color: white;
          text-transform: uppercase;
          margin-top: 1rem;
        }
      }

      .card__description {
        color: $color-primary;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.btn-more {
  color: black;
  text-decoration: none;
  text-transform: capitalize;
}
