.section-about {
  width: 100%;
  height: 80vh;
  // background-color: $color-secondary;
  margin-top: 1rem;
  @include respond(tab-land) {
    height: 100%;
  }

  @include respond(tab-port) {
    height: 100%;
  }
  @include respond(phone) {
    height: 100%;
  }

  .image-side {
    position: relative;

    @include respond(phone) {
      display: none;
    }

    .img-bg {
      background-color: $color-primary;
      width: 35rem;
      height: 35rem;
      position: absolute;
      top: 20%;
      left: 10%;

      @include respond(tab-land) {
        width: 30rem;
        height: 30rem;
      }
      @include respond(tab-port) {
        width: 25rem;
        height: 25rem;
        top: 15%;
        left: 10%;
      }
      @include respond(phone) {
        top: 15%;
      }
    }
    .about-image {
      position: absolute;
      right: -10%;
      top: -10%;
    }
    img {
      width: 35rem;
      height: 35rem;
      object-fit: cover;

      @include respond(tab-land) {
        width: 30rem;
        height: 30rem;
      }
      @include respond(tab-port) {
        width: 25rem;
        height: 25rem;
      }
    }
  }
  .about-content {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: 0.05rem;
    text-align: left;
    padding-top: 7rem;
    padding-left: 2rem;

    @include respond(tab-port) {
      // margin-left: 5rem;
      text-align: center;
      padding-top: 4rem;
    }

    h1 {
      text-transform: uppercase;
      color: $color-primary;
      font-weight: 600;
      font-size: 3.7rem;
    }
    .about-text {
      text-align: left;
      font-weight: 400;
    }

    .btn-download {
      padding: 0.8rem 2.5rem;
      border: 2px solid $color-primary;
      font-size: 1.5rem;
      background: none;
      color: white;
      text-decoration: none;
      letter-spacing: 0px;

      &:hover {
        // background-color: $color-primary;
        color: $color-primary;
        border: 2px solid $color-primary;
        transition: all 0.5s;
      }
    }
  }
}
.btn-primary {
  padding: 0.8rem 2.5rem;
  border: 2px solid $color-primary;
  background-color: $color-primary;
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 2rem;
  text-decoration: none;
  letter-spacing: 0px;
  color: black;

  &:hover {
    background-color: $color-secondary;
    border: 2px solid $color-primary;
    color: white;
    transition: all 0.5s;
  }
}
.col {
  @include respond(phone) {
    display: block;
  }
}
