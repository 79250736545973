.section-education {
  width: 100%;
  height: 100%;

  @include respond(tab-port) {
    height: 100%;
  }
  @include respond(phone) {
    height: 100%;
  }

  .education-container {
    width: 50%;
    min-height: 10rem;
    margin: 5rem auto;

    @include respond(tab-land) {
      width: 60%;
    }

    @include respond(tab-port) {
      width: 70%;
    }
    @include respond(phone) {
      width: 80%;
    }

    .education-card {
      color: white;
      padding: 0.5rem 7rem 0 7rem;
      // background-color: white;
      border: 2px solid $color-primary;
      transform: skewX(-12deg);

      & > * {
        transform: skewX(12deg);
      }
      .level {
        color: $color-primary;
        text-decoration: underline;
      }
      .institute {
        font-size: 1.5rem;
      }
    }
  }
}
.hor-line {
  opacity: 1;
  border: 0.5px solid $color-primary;
}
.heading-primary {
  text-transform: uppercase;
  color: $color-primary;
  // color: white;
  font-weight: 600;
  font-size: 3.7rem;
}
