.contact-table {
  border-collapse: collapse;

  td {
    padding: 1rem 0;
    text-align: left;

    span {
      color: $color-primary;
      margin-right: 1rem;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
  th {
    writing-mode: vertical-rl;
  }
}
.Icon {
  color: $color-primary;
}
.copyright_section {
  .copyright {
    color: white;
  }
}
.last-line {
  margin-bottom: 0;
}
.contact-content {
  @include respond(phone) {
    margin-left: 3rem;
  }
}
.contact-container {
  @include respond(tab-land) {
    height: 100%;
    margin-bottom: 10rem;
  }
}
